<template>
  <section>
    <v-sheet
      color="white"
      elevation="4"
      outlined
      rounded
      width="352px"
      :min-height="380"
      class="pa-5 mb-12 rounded-lg"
    >
      <div class="text-center title mb-16 mt-4">
        <p>Reset Password</p>
      </div>
      <v-form id="resetPassword" ref="resetPassword" :model="form" :rules="rules" @submit.native.prevent="resetPassword">
        <v-text-field
          ref="password"
          v-model="form.password"
          class="mb-8 mt-4"
          dense
          :label="$_strings.welcomePage.PASSWORD"
          :type="passwordShow ? 'text' : 'password'"
          :rules="rules.password"
          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="passwordShow = !passwordShow"
        ></v-text-field>
        <v-text-field
          ref="password"
          v-model="form.repeatPassword"
          class="mb-8 mt-4"
          dense
          :label="$_strings.welcomePage.REPEAT_PASSWORD"
          :type="repeatPasswordShow ? 'text' : 'password'"
          :rules="rules.repeatPassword"
          :append-icon="repeatPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="repeatPasswordShow = !repeatPasswordShow"
        ></v-text-field>
        <v-btn rounded large class="background-denim white--text font-weight-bold body-2 w-100" type="submit" :disabled="!form.repeatPassword">
          Reset Password
        </v-btn>
      </v-form>
    </v-sheet>
    <v-footer>
      <p>Copyright &copy; 2019 - {{yearNow}} <a target="_blank" href="http://">fastlog.id</a>, All Rights Reserved. </p>
    </v-footer>
    <dialog-terms-conditions :visible="dialogTermsConditions" @setDialog="setDialog"/>
  </section>
</template>

<script>

export default {
  name: 'reset-password',
  mounted() {
    const { token } = this.$route.query;
    if (token) this.decodeAccount(token);
  },
  data() {
    return {
      yearNow: new Date().getFullYear(),
      form: {
        email: '',
        phoneNo: '',
        password: '',
        repeatPassword: '',
      },
      passwordShow: false,
      repeatPasswordShow: false,
      rules: {
        password: [
          (v) => {
            if (v) {
              const upperCaseLetters = /[A-Z]/g;
              const numbers = /[0-9]/g;
              const symbol = /[$-/:-?{-~!"^_`\\[\]\s@]/;
              if ((!symbol.test(v) || v.includes(' ') || !v.match(upperCaseLetters)) || (!v.match(numbers)) || (v.length < 6)) {
                return 'Password wajib alphanumeric, kapital, simbol, tidak memiliki spasi dan minimal 6 karakter';
              }
              return !!v;
            }
            return this.$_strings.messages.validation.REQUIRED('Kata Sandi');
          },
        ],
        repeatPassword: [
          (v) => {
            if (v !== this.form.password) {
              return this.$_strings.messages.validation.PASSWORD_NOT_MATCH;
            }
            if (!v) {
              return this.$_strings.messages.validation.REQUIRED('Konfirmasi Kata Sandi');
            }
            return true;
          },
        ],
      },
    };
  },
  computed: {
    verify() {
      return this.verified && this.isAgree;
    },
  },
  methods: {
    decodeAccount(token) {
      this.$root.$loading.show();
      this.$_services.account.decodeToken(token)
        .then((result) => {
          this.form.email = result.data.email;
          this.form.phoneNo = result.data.phoneNo;
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
    resetPassword() {
      const valid = this.$refs.resetPassword.validate();
      if (valid) {
        this.$root.$loading.show();
        this.$_services.account.setResetPassword(this.form)
          .then((result) => {
            this.$root.$loading.hide();
            this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_RESET_USER_MESSAGE_TEXT);
            this.$router.push('/welcome/login');
          })
          .catch((err) => {
            this.$root.$loading.hide();
          });
      }
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
  },
};
</script>

<style lang="scss" scoped>
section .v-footer {
  background: none !important;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
